// Custom react-select styles
.react-select {
  &--input-v2 {
    .react-select__control {
      min-height: 64px !important;
      border-color: rgba(0, 0, 0, 0.3);
      border-radius: 8px;
    }

    .react-select__indicators {
      min-width: 48px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      background: rgba(245, 245, 245);

      span {
        display: none;
      }
    }

    .react-select__value-container {
      padding: 2px 16px;
    }

    .react-select__dropdown-indicator {
      padding: 8px 9px;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    .react-select__placeholder {
      color: #caced5;
      font-weight: bold;
    }
  }

  &--has-errors {
    .react-select__control {
      border-color: rgba(190, 14, 14, 1);

      &:hover {
        border-color: rgba(190, 14, 14, 1);
      }
    }

    .react-select__indicators {
      background: rgba(190, 14, 14, 0.1);
    }

    .react-select__dropdown-indicator {
      svg {
        fill: rgba(190, 14, 14, 0.2);
      }
    }

    .css-1wa3eu0-placeholder {
      color: rgba(190, 14, 14, 1);
      font-weight: 600;
    }
  }
}
