@import 'tippy.js/dist/tippy.css';
// Theme
@import 'tippy.js/themes/light.css';
// Animation
@import 'tippy.js/animations/shift-away.css';

.tippy-content {
  padding: 0;
}

.tippy-box.no-theme {
  background-color: none;

  > .tippy-content {
    padding: 0;
  }

  > .tippy-arrow {
    display: none;
  }
}

.tippy-box {
  border-radius: 10px;
}

.tippy-box[data-theme~=error] {
  --color: #be0e0e;

  background-color: var(--color);
  color: white;
  box-shadow: 0 0 20px 4px rgba(154, 161, 177, 0.15), 0 4px 80px -8px rgba(36, 40, 47, 0.25), 0 4px 4px -2px rgba(91, 94, 105, 0.15);

  &[data-placement^=top] > .tippy-arrow::before {
    border-top-color: var(--color);
  }

  &[data-placement^=bottom] > .tippy-arrow::before {
    border-bottom-color: var(--color);
  }

  &[data-placement^=left] > .tippy-arrow::before {
    border-left-color: var(--color);
  }

  &[data-placement^=right] > .tippy-arrow::before {
    border-right-color: var(--color);
  }

  & > .tippy-backdrop { background-color: #fff; }
  & > .tippy-svg-arrow { fill: #fff; }
}
