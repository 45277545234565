$fontName: 'Wise Sans v2';
$fontFamily: $fontName, 'sans-serif';

@mixin font($file, $weight: normal, $style: normal) {
  @font-face {
    font-display: swap;
    font-weight: $weight;
    font-style: $style;
    font-family: '#{$fontName}';
    src: url('./fonts/#{$file}.ttf');
  }
}

@include font('Figtree-Light',            300, normal);
@include font('Figtree-LightItalic',      300, italic);
@include font('Figtree-Regular',          400, normal);
@include font('Figtree-Italic',           400, italic);
@include font('Figtree-Medium',           500, normal);
@include font('Figtree-MediumItalic',     500, italic);
@include font('Figtree-SemiBold',         600, normal);
@include font('Figtree-SemiBoldItalic',   600, italic);
@include font('Figtree-Bold',             700, normal);
@include font('Figtree-BoldItalic',       700, italic);
@include font('Figtree-ExtraBold',        800, normal);
@include font('Figtree-ExtraBoldItalic',  800, italic);
@include font('Figtree-Black',            900, normal);
@include font('Figtree-BlackItalic',      900, italic);

:root {
  --font-family: #{$fontFamily};

  font-weight: 400;
  font-size: 16px;
}

html,
input,
.tippy-box {
  font-family: '#{$fontFamily}'; // Fallback for older browsers
  font-family: var(--font-family);
}
