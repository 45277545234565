html {
  .rdp-months {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rdp {
  --rdp-cell-size: 40px;
  --rdp-accent-color: #639 !important;
  --rdp-background-color: #6393 !important;

  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);

  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);
}

.select-week-modal {
  .rdp-day_outside {
    color: rgba(0, 0, 0, 0.5);
  }

  .rdp-day--selectedRange {
    border: 2px solid #639;
    border-right: none;
    border-left: none;
    border-radius: 0;
    background-color: #f6f1fb !important;
    color: #639 !important;
    font-weight: bold !important;

    &.rdp-day_selected:focus {
      border-right: none;
      border-left: none;
      background-color: #e0cdf2 !important;
    }
  }

  .rdp-day--hoverRange:not(.rdp-day--selectedRange) {
    border: none !important;
    border-radius: 0;
    background-color: #f5edfd !important;
  }

  .rdp-cell {
    &:first-child {
      .rdp-day--hoverRange {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }

      .rdp-day--selectedRange {
        border-left: 2px solid #639;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }

    &:last-child {
      .rdp-day--hoverRange {
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      .rdp-day--selectedRange {
        border-right: 2px solid #639;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}

.payment-date-picker {
  --rdp-cell-size: 46px;
}
