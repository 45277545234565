$this: 'maintenance-page';

.#{$this} {
  @apply bg-purple;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  &__header {
    position: relative;
    width: 100%;
  }

  &__parallax {
    position: absolute;
    top: 20%;
    right: 0;
    bottom: 0;
    left: 1%;
    z-index: 0;
    z-index: 1;
    width: 100%;
    background-image: url('/images/particles.svg');
    background-position: center;
    background-position: 50% 50%;
    background-size: 15%;
    background-repeat: no-repeat;
    pointer-events: none;
    transform: scaleY(-1);
  }

  &__img {
    margin-bottom: -2%;

    @media (min-width: 1024px) {
      margin-bottom: -5%;
    }

    @media (min-width: 1536px) {
      margin-bottom: -4%;
    }
  }

  &__band {
    @apply bg-white;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 400px;
    padding-bottom: 20%;

    @media (min-width: 1024px) {
      min-height: 275px;
      padding-bottom: 5%;
    }

    @media (min-width: 1536px) {
      min-height: 200px;
      padding-bottom: 10%;
    }
  }

  &__content {
    @apply bg-white;

    position: absolute;
    text-align: center;
  }

  &__social {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
