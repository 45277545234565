@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts';
@import 'maintenance';
@import 'tooltips';
@import 'react-select';
@import 'react-day-picker';

* {
  box-sizing: border-box;
  letter-spacing: 0.03em;
  scroll-behavior: smooth !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#__next {
  @apply bg-grey;

  overflow-x: auto;
  overflow-y: auto;
  width: 100vw;
  height: 100vh;
}

:root {
  // #000000
  --var-theme: 0 0 0;
  --var-theme-dark: 0 0 0;
  --var-theme-light: 0 0 0;
  --var-theme-inverted: 0 0 0;
  --var-theme-dark-inverted: 0 0 0;
  --var-theme-light-inverted: 0 0 0;
}

body {
  &[data-theme='purple'] {
    // #663399
    --var-theme: 102 51 153;
    // #52297c
    --var-theme-dark: 82 41 124;
    // #b744ca
    --var-theme-light: 183 68 202;

    // #009768
    --var-theme-inverted: 0 151 103;
    // #00704d
    --var-theme-inverted-dark: 0 112 77;
    // #00b37b
    --var-theme-inverted-light: 0 179 123;
  }

  &[data-theme='green'] {
    // #009768
    --var-theme: 0 151 103;
    // #00704d
    --var-theme-dark: 0 112 77;
    // #00b37b
    --var-theme-light: 0 179 123;

    // #663399
    --var-theme-inverted: 102 51 153;
    // #52297c
    --var-theme-inverted-dark: 82 41 124;
    // #b744ca
    --var-theme-inverted-light: 183 68 202;
  }
}

@layer components {
  .select-parent:hover > .select-child {
    @apply block;
  }
}

.custom-scrollbar-styling {
  /* width */
  &::-webkit-scrollbar {
    width: 13px;
  }

  /* color, shape and border */
  &::-webkit-scrollbar-thumb {
    border: 3px solid #f6f7ed;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.3);
  }

  /* on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
  }
}

.custom-scrollbar-ios {
  /* width */
  &::-webkit-scrollbar {
    width: 16px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    border: 6px solid transparent;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border: 6px solid rgba(0, 0, 0, 0);
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    background-clip: padding-box;
  }
}

.block-renderer-styling {
  b,
  strong {
    font-weight: 900;
  }

  a {
    color: #639;
    font-weight: bold;
    text-decoration: underline;
  }

  h5 {
    margin: 0.6em 0 0.2em 0;
    color: black;
    font-weight: bold;
    font-size: 1.1em;
  }

  ol {
    margin: 0 0 0 2em;
    padding: unset;
    list-style: decimal;

    > li > ol {
      list-style: lower-latin;
    }
  }
}

.tippy-arrow-left > .tippy-arrow {
  margin-left: 1.5em;
  transform: none !important;
}
